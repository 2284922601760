window.jQuery = window.$ = require('jquery');

require('bootstrap');

var INVALID_EMAIL = 'Please enter a valid email address.';
var PASSWORD_TOO_SHORT = 'Your password must be more than 4 characters.';

var $loginView;
var $loginModalLabel;
var $recoverView;
var $hasError;

function hrefFBUrl($selector) {
  return function(response) {
    response = JSON.parse(response);
    $selector.prop('href', response.fb_url);
  };
}

function redirectResponse(response) {
  response = JSON.parse(response);
  window.location.href = response.redirect;
}

function showErrorText($selector) {
  return function(response) {
    response = JSON.parse(response.responseText);
    showText($selector, response.error);
  };
}

function showText($selector, text) {
  $selector.text(text);
  $selector.show();
}

function resetModals() {
  $loginModalLabel.text("Log in to your account");
  $loginView.show();
  $recoverView.hide();
  $hasError.hide();
    $('#login-fail').hide();
  $('.response-error').hide();
  $('.form-control').val("");
}

function showError($label, error) {
  var errorMatch = {
    email: "Facebook login failed, please allow email access.",
    perm: "Facebook login failed, please allow access.",
    fbissue: "Facebook login failed. Please register with email/password.",
    serverError: "Internal server error, please try again later",
    nofb: "No user found for that Facebook account"
  };

  showText($label, error in errorMatch ? errorMatch[error] : error);
}

function getParameterByName(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  var results = regex.exec(location.search);
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

function emailValidator(val) {
    var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return re.test(val);
}

function bindEvents() {

  $('#forgot-password').click(function() {
    $loginModalLabel.text("RESET PASSWORD");
    $recoverView.fadeIn('fast');
    $loginView.hide();
  });

  $('#recover-login').click(function() {
    $loginModalLabel.text("Log in to your account");
    resetModals();
    $loginView.fadeIn('fast');
  });

  $('#loginModal').on('show.bs.modal', function() {
    $.get('/account/get_fb_login').done(hrefFBUrl($("#fb-login-link")));
  });

  $('#loginModal, #signupModal').on('hidden.bs.modal', resetModals);

  $('#signupModal').on('show.bs.modal', function() {
    $.get('/account/get_fb_register').done(hrefFBUrl($('#fb-signup-link')));
  });

  $('.signup-pane').submit(function() {
    var $signupEmailError = $('#signup-email-error');

    var email = $('#signup-email').val();
    var password = $('#signup-password').val();
    var confirmation = $('#signup-confirm').val();

    $hasError.hide();

    var isError = false;

    // Front-end validation
    if (!emailValidator(email)) {
      showText($signupEmailError, INVALID_EMAIL);
      isError = true;
    }

    if (password.length < 4) {
      showText($('#signup-password-error'), PASSWORD_TOO_SHORT);
      isError = true;
    }

    if (password !== confirmation) {
      showText($('#signup-confirm-error'), "Your passwords don't match");
      isError = true;
    }

    if (isError) {
      return false;
    }

    $.post('/account/register', {
      email: email,
      password: password,
      confirmation: confirmation
    }).done(redirectResponse)
      .fail(showErrorText($signupEmailError));

    // Return false to prevent the default form submission behavior.
    return false;

  });

  $('.login-pane').submit(function() {
    var email = $('#login-email').val();
    var password = $('#login-password').val();

    $hasError.hide();
    $('#login-fail').hide();

    var isError = false;

    // Front-end validation
    if (!emailValidator(email)) {
      showText($('#login-email-error'), INVALID_EMAIL);
      isError = true;
    }

    if (password.length < 4) {
      showText($('#login-password-error'), PASSWORD_TOO_SHORT);
      isError = true;
    }

    if (isError) {
      return false;
    }

    $.post('/account/login', {
      email: email,
      password: password
    }).done(redirectResponse)
      .fail(showErrorText($('#login-email-error')));

    return false;
  });

  $('.recover-pane').submit(function() {
    var $recoverFail = $('#recover-fail');

    var email = $('#recover-email').val();

    $hasError.hide();
    $recoverFail.hide();

    // Front-end validation
    if (!emailValidator(email)) {
      showText($recoverFail, INVALID_EMAIL);
      return false;
    }

    $.post('/account/begin_reset', {email: email})
      .done(function() {
        $recoverFail.css('color', 'green');
        showText($recoverFail, 'Recovery email has been sent!');
      })
      .fail(showErrorText($recoverFail));

    return false;
  });
}

function showSignupModal(error) {
  $('#signupModal').modal('show');
  $('#signup-info').show();
  // This is a neat trick which uses short-circuiting to only call the function
  // if the first value is truthy. Equivalent to if (error) showError;
  error && showError($('#signup-fb-fail'), error);
}

function showLoginModal(error) {
  $('#loginModal').modal('show');
  error && showError($('#login-fb-fail'), error);
}

module.exports = function() {
  $loginView = $('#login-view');
  $loginModalLabel = $('#loginModalLabel');
  $recoverView = $('#recover-view');
  $hasError = $('.has-error');

  $hasError.hide();
  $('.response-error').hide();
  bindEvents();

  var modalType = window.location.hash;
  var param = getParameterByName('error');

  if (modalType === '#signupModal') {
    showSignupModal(param);
  } else if (modalType === '#loginModal') {
    showLoginModal(param);
  }
};