window.jQuery = window.$ = require('jquery');

// Muting the youtube video
var bgVideoPlayer;
var Modal = require('../modal/modal');
var ScrollReveal = require('scrollreveal');

require('../../vendor/jquery.waypoints');
require('../../vendor/velocity.min');
require('../../vendor/typed.min');
require('jquery.scrollto');

var animatingScroll = false;

// This stuff needs to be available within the global scope.
// I don't like it, but we need to do this to get browsers to realize
// the video is muted.
window.onYouTubeIframeAPIReady = function() {
    bgVideoPlayer = new YT.Player('youtube-bg-iframe', {
        events: {
            'onReady': onPlayerReady
        }
    });
};

function onPlayerReady() {
    bgVideoPlayer.mute();
}

$(function(){
  $('#youtube-bg-iframe').css({ width: $(window).innerWidth() + 'px', height: $(window).innerHeight() + 'px' });

  $(window).resize(function(){
    var $sponsors = $("#sponsors");
    
    var $backgroundthings = $("#background-things");
    var height1 = $sponsors.height();
    $backgroundthings.css("height", height1);
    $sponsors.css("margin-top", (-height1));


    var $faq = $("#faq");
    var $schedule = $("#schedule");

    var $shapes = $(".shapebottom");
    var height2 = $faq.height();
    var height3 = $schedule.height();
    var totalheight = (height1 + height2 + height3 + 300);
    $shapes.css("height", totalheight);
    $shapes.css("margin-top", (-totalheight));

    $('#youtube-bg-iframe').css({ width: $(window).innerWidth() + 'px', height: $(window).innerHeight() + 'px' });
  });
});

$(function() {
    // BG video
    var $sponsors = $("#sponsors");
    
    var $backgroundthings = $("#background-things");
    var height1 = $sponsors.height();
    $backgroundthings.css("height", height1);
    $sponsors.css("margin-top", (-height1));


    var $faq = $("#faq");
    var $schedule = $("#schedule");

    var $shapes = $(".shapebottom");
    var height2 = $faq.height();
    var height3 = $schedule.height();
    var totalheight = (height1 + height2 + height3 + 300);
    $shapes.css("height", totalheight);
    $shapes.css("margin-top", (-totalheight));

    window.sr = ScrollReveal();
    sr.reveal('.scrollreveal', { duration: 1000 });

    Modal();
    var $home = $("#home");
    var $vidmask = $(".video-mask");
    var $video = $(".bg-video");

    var videoRatio = 16/9;
    function resizeBGVideo() {
        var width = $vidmask.width();
        var height = $vidmask.height();

        var containerRatio = width/height;

        if (containerRatio < videoRatio) {
            // too narrow
            $video.css("height", height);
            $video.css("top", 0);

            var newWidth = height * videoRatio;
            var wDiff = (newWidth - width)

            $video.css("width", newWidth);
            $video.css("left", -wDiff);
        } else {
            // too wide
            $video.css("width", width);
            $video.css("left", 0);

            var newHeight = width / videoRatio;
            var hDiff = (newHeight - height) / 2;

            $video.css("height", newHeight);
            $video.css("top", -hDiff);
        }
    }

    resizeBGVideo();
    $(window).resize(resizeBGVideo);

    var $navLinks = $(".nav-scrolling-links a");
    var sectionBarriers = $navLinks.map(function() {
        return $(this).attr("href");
    }).get();
    function scrollNavBarToLink(href) {
        $navLinks.removeClass("highlighted");
        var $newSelected = $("#navbar a[href='" + href + "']");
        $newSelected.addClass("highlighted");
        $("#navbar .nav-scrolling-links ul").scrollTo($newSelected, {
            offset: {
                left: -20
            },
            duration: 100
        });
    }

    $(document).ready(function(){
      $(window).scroll(function() { // check if scroll event happened
        var $vidmask = $(".video-mask");
        var height = $vidmask.height();
        var $about = $("#about");
        var aboutheight = $about.height();
        var $stories = $("#hab-stories");
        var storiesheight = $stories.height();
        var $faq = $("#faq");
        var faqheight = $faq.height();
        var $sponsors = $("#sponsors");
        var sponsorheight = $sponsors.height();
        
        if ($(document).scrollTop() > (height - 200)) { // check if user scrolled more than height from top of the browser window
          $("#navbar").addClass("opaque-nav"); // if yes, then change the color of class "navbar-fixed-top" to white (#f8f8f8)
        } else {
          $("#navbar").removeClass("opaque-nav"); // if not, change it back to transparent
    
        } 

        if ($(document).scrollTop() < (height - 200)) {
            $("#navbar li a#abt").css("text-decoration", "none");
            $("#navbar li a#sto").css("text-decoration", "none");
            $("#navbar li a#fa").css("text-decoration", "none");
            $("#navbar li a#spo").css("text-decoration", "none");
            $("#navbar li a#sch").css("text-decoration", "none");
        } else if (($(document).scrollTop() > (height - 200)) 
            && ($(document).scrollTop() < (height + aboutheight))) {
          $("#navbar li a#abt").css("text-decoration", "underline");
          $("#navbar li a#sto").css("text-decoration", "none");
            $("#navbar li a#fa").css("text-decoration", "none");
            $("#navbar li a#spo").css("text-decoration", "none");
            $("#navbar li a#sch").css("text-decoration", "none");
        } else if (($(document).scrollTop() > (height + aboutheight)) 
            && ($(document).scrollTop() < (height + aboutheight + storiesheight - 100))){
          $("#navbar li a#abt").css("text-decoration", "none");
          $("#navbar li a#sto").css("text-decoration", "underline");
            $("#navbar li a#fa").css("text-decoration", "none");
            $("#navbar li a#spo").css("text-decoration", "none");
            $("#navbar li a#sch").css("text-decoration", "none");
        } else if (($(document).scrollTop() > (height + aboutheight + storiesheight - 100))
            && ($(document).scrollTop() < (height + aboutheight + storiesheight + faqheight))){
            $("#navbar li a#abt").css("text-decoration", "none");
            $("#navbar li a#sto").css("text-decoration", "none");
            $("#navbar li a#fa").css("text-decoration", "underline");
            $("#navbar li a#spo").css("text-decoration", "none");
            $("#navbar li a#sch").css("text-decoration", "none");
        } else if (($(document).scrollTop() > (height + aboutheight + storiesheight + faqheight))
            && ($(document).scrollTop() < (height + aboutheight + storiesheight + faqheight + sponsorheight))){
            $("#navbar li a#abt").css("text-decoration", "none");
            $("#navbar li a#sto").css("text-decoration", "none");
            $("#navbar li a#fa").css("text-decoration", "none");
            $("#navbar li a#spo").css("text-decoration", "underline");
            $("#navbar li a#sch").css("text-decoration", "none");
        } else {
            $("#navbar li a#abt").css("text-decoration", "none");
            $("#navbar li a#sto").css("text-decoration", "none");
            $("#navbar li a#fa").css("text-decoration", "none");
            $("#navbar li a#spo").css("text-decoration", "none");
            $("#navbar li a#sch").css("text-decoration", "underline");

      };
    });

    // Nav Bar Links
    var navHeight = $(".scrolling-navbar").height();
    $(".nav-scrolling-links a, .down-indicator-link").click(function(e) {
        var href = $(this).attr('href');
        var top = $(href.substring(1, href.length)).offset().top - navHeight + 1;

        // TODO: check if this is the down indicator, and if so don't cut out first char

        animatingScroll = true;

        $(window).scrollTo(top, {
            duration: 500,
            onAfter: function() {
                animatingScroll = false;
                scrollNavBarToLink(href);
            }
        });

        return false;
    });
});
});
